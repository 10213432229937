<template>
  <div class="backgroundLoin">
    <el-card class="box-card clearfix">
      <div class="lf boxLeft">
        <img src="../../assets/login/manageLogin.png" />
      </div>
      <div class="rf boxRight">
        <div class="boxRightTit">考务管理登录</div>
        <el-form ref="formLion" :rules="rules" :model="formLion">
          <el-form-item label="登录方式" v-if="0">
            <el-radio-group v-model="formLion.loginType">
              <el-radio :disabled="disabled" :label="1">账号</el-radio>
              <el-radio :disabled="disabled" :label="2">手机号</el-radio>
              <el-radio :disabled="disabled" :label="3">邮箱</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="account">
            <el-input
              prefix-icon="el-icon-user-solid"
              v-model="formLion.account"
              :placeholder="accountText"
              :disabled="disabled"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="formLion.password"
              placeholder="请输入密码"
              show-password
              prefix-icon="el-icon-lock"
              :disabled="disabled"
            ></el-input>
          </el-form-item>
          <el-form-item style="margin: 40px 0 20px">
            <el-button
              v-throttle
              type="primary"
              :loading="loadingBtn"
              @click="onLogin('formLion')"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
        <!-- <div class="clearfix">
          <router-link to="./register">
            <el-link type="primary" class="rf">开通账号</el-link>
          </router-link>
        </div>-->
      </div>
    </el-card>
  </div>
</template>

<script>
import { login } from '@/api/auth.js'
import { setToken, setName } from '@/utils/token.js'
export default {
  name: 'manageLogin',
  data() {
    return {
      formLion: {
        account: '',
        // 登录类型,1为账号登录，2为手机号登录，3为邮箱登录
        loginType: 1,
        password: '',
        userType: 0,
        loginTerminalType: 11,
      },
      accountText: '请输入用户名',
      loadingBtn: false,
      disabled: false,
      // 验证
      rules: {
        account: [{ required: true, message: '账号不能为空', trigger: 'blur' }],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
        ],
      },
    }
  },
  watch: {
    'formLion.loginType': function (newVal, oldVal) {
      // this.formLion.userType = newVal;
      switch (newVal) {
        case 2:
          this.accountText = '请输入手机号'
          break
        case 3:
          this.accountText = '请输入邮箱'
          break
        default:
          this.accountText = '请输入账号'
      }
    },
  },
  // 在组件的 created 生命周期钩子中添加监听回车事件
  created() {
    document.addEventListener('keydown', this.handleEnterKey)
  },

  // 在组件销毁时移除监听回车事件，避免内存泄漏
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleEnterKey)
  },
  methods: {
    // 处理回车事件的方法
    handleEnterKey(event) {
    console.log('event',event)
      if (event.keyCode === 13) {
        this.onLogin('formLion')
      }
    },

    onLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.disabled = this.loadingBtn = true
          // 在此发送请求
          login(this.formLion)
            .then((res) => {
              this.disabled = this.loadingBtn = false
              console.log(res)
              if (res.success) {
                // 存储Token vuex 或者 浏览器上储存
                setToken(res.data.token)
                setName(this.formLion.account)
                this.$message.success('登录成功，正在转跳首页···')
                // 成功后跳转路由
                setTimeout(() => {
                  this.$router.replace('/home')
                }, 500)
              } else {
                this.$message.warning(res.msg)
              }
            })
            .catch((err) => {
              this.disabled = this.loadingBtn = false
              this.$message.warning('登录失败')
            })
        } else {
          return false
        }
      })
    },
  },
}
</script>
<style scoped>
.backgroundLoin {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: url(../../assets/login/manageLoginBg.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .box-card {
  width: 998px;
  border-radius: 10px;
  box-shadow: 2px 2px 4px 1px #ffffffa8;
}
::v-deep .el-card__body {
  padding: 0 !important;
}
.el-card__body > div {
  width: 50%;
  box-sizing: border-box;
}
.boxLeft {
  background: #daecfd;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 58px;
  padding-top: 100px;
  box-sizing: border-box;
}
.boxLeft img {
  width: 100%;
}
.boxRight {
  padding: 58px;
  padding-top: 100px;
  box-sizing: border-box;
}
.boxRightTit {
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 38px;
  letter-spacing: 6px;
}
.el-button--primary {
  width: 100%;
}
</style>
